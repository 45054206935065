import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import ArrowLink from '../ArrowLink'

import './offlinelicence.scss'

export default () =>
    <StaticQuery
        query={graphql`
        query {
            offlineIllustration: file(relativePath: { eq: "homepage/illustration_offline.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1500) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        `}
        render={data => (
            <section className="offlineLicenseBlock">
                <Img fluid={data.offlineIllustration.childImageSharp.fluid} alt="Offline licensing illustration"/>
                <div>
                    <h2>Offline License Management</h2>
                    <p>Enable and enforce licensing for machines that do not have an active internet connection, for environments with restricted internet access, or limited ability to check the status of a license online.</p>
                    <ul>
                        <li>Offline License Activation & Deactivation</li>
                        <li>Fallback to a local license file check when internet is unavailable</li>
                        <li>Set a grace period for an online license check</li>
                        <li>Support Floating licenses for non-internet enabled customers.</li>
                    </ul>
                   
                    <ArrowLink to="https://docs.licensespring.com/docs/offline-licensing" label="View Offline Licensing Docs" anchor={true} />
                </div>
            </section>
        )}
    />