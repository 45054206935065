import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Button from "../../components/Button"

import ArrowLink from "../../components/ArrowLink"

import "./3dparty.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logos3dparty: file(
          relativePath: { eq: "homepage/logos_3rd-party-integration.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="thirdpartyComponent">
        <div className="container">
          <div className="row">
            <div className="col__md-50">
              <h2>
                Seamless <br />
                3rd party integration
              </h2>
              <p>
                Dispatch licenses immediately when an order is received from
                your e-commerce platform, and share license information and
                states with other 3rd party services.
              </p>
              <Button
                to="/get-started/"
                label="Open trial account"
                color="white"
                shadow
                size="big"
              />
            </div>
            <div className="w-100 col__md-50">
              <ArrowLink
                label="View Docs on Integration"
                to="https://docs.licensespring.com/integrations"
                color="white"
                anchor={true}
                target="_blank"
              />
              <Img
                fluid={data.logos3dparty.childImageSharp.fluid}
                alt="Third party logos"
              />
            </div>
          </div>
        </div>
      </section>
    )}
  />
)
