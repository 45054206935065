import React from 'react'

import Hand from '../../../../assets/img/hand.svg'
import Lock from '../../../../assets/img/lock.svg'
import Cloud from '../../../../assets/img/cloud.svg'
import Shield from '../../../../assets/img/shield.svg'

import './blocks.scss'

export default () =>
    <section className="homePage__blocks">
        <div>
            <div>
                <img src={Cloud} alt="Illustration cloud" />
                <h2>Cloud Based</h2>
                <p>Works out of the box,  <br />fully scalable</p>
            </div>
        </div>
        <div>
            <div>
                <img src={Lock} alt="Illustration lock" />
                <h2>Prevent</h2>
                <p>Unlicensed usage  <br />of your software</p>
            </div>

        </div>
        <div>
            <div>
                <img src={Hand} alt="Illustration hand" />
                <h2>Protect</h2>
                <p>your software IP<br /><br /></p>
            </div>
        </div>
        <div>
            <div>
                <img src={Shield} alt="Illustration shield" />
                <h2>Enforce</h2>
                <p>your software license <br />agreement with your end-users</p>
            </div>
        </div>
    </section>