import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Intro from "../components/Pages/Index/Intro"
import Slider from "../components/Pages/Index/Slider"
import Blocks from "../components/Pages/Index/Blocks"
import Apps from "../components/Apps"
import OfflineLicenseBlock from "../components/OfflineLicenseBlock"
import Features from "../components/Features"
import ThirdParty from "../components/ThirdParty"
import TrustedBlock from "../components/TrustedBlock"
import BottomBlock from "../components/BottomBlock"

import "./index.scss"
const schemaMarkup = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "LicenseSpring Software",
  "url": "https://licensespring.com/",
  "logo": "https://licensespring.com/static/logo-text-0e046bbc0a6d2815a94bbbe255b136d1.png",
  "sameAs": [
    "https://www.linkedin.com/company/licensespring/mycompany/",
    "https://twitter.com/LicenseSpring",
    "https://www.youtube.com/@licensespring"
  ]
}
</script>
`

export default ({ location }) => (
  <Layout className="page homePage">
    <SEO
      title="LicenseSpring: Software Licensing Cloud"
      description="Reliable, cross-platform, platform for licensing your software. Used in large deployments and standalone applications alike. Open your account today!"
      schemaMarkup={schemaMarkup}
    />
    <div className="container">
      <div className="homepage-row">
        <Intro urlParams={location.search} />
        {/* <Slider /> */}
        <div className="video">
          <iframe
            src="https://www.youtube.com/embed/PTPx4EnM5Ro"
            title="LicenseSpring Explained"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <Blocks />
      <Apps />
      <OfflineLicenseBlock />
      <Features title content />
    </div>
    <ThirdParty />
    <TrustedBlock />
    <BottomBlock />
  </Layout>
)
