import React from "react"
import Button from "../../../Button"
import "./intro.scss"

export default ({ urlParams }) => {
  const [title, setTitle] = React.useState(
    <h1>
      The <span>Software Licensing Platform</span>
      <br /> for SaaS, ISVs and Indie Developers
    </h1>
  )
  const titleArray = [
    {
      url: "h1=a&b1",
      title: (
        <h1>
          The <span>Software Licensing Platform</span>
          <br /> for SaaS, ISVs
        </h1>
      ),
    },
    {
      url: "h1=a&b2",
      title: (
        <h1>
          The <span>Software Licensing Platform</span>
          <br /> for ISV
        </h1>
      ),
    },
    {
      url: "h1=a&b3",
      title: (
        <h1>
          The <span>Software Licensing Platform</span>
          <br /> for Indie Developers
        </h1>
      ),
    },
  ]
  React.useEffect(() => {
    titleArray.forEach(
      (item) => urlParams === `?${item.url}` && setTitle(item.title)
    )
  }, [])
  return (
    <section className="homePage__intro col__md-50">
      {title}
      <ul>
        <li>Implement any Licensing Model</li>
        <li>Offline Activation and License Checks</li>
        <li>SDKs for all major development environments</li>
      </ul>
      <Button
        to="/get-started/"
        color="blue"
        label="Open trial account"
        shadow
        disclaimer
        size="big"
      />
    </section>
  )
}
